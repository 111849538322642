//import zia from './zia.svg';
import Dragon from './components/Dragon.js';
import './App.css';
//import PdfViewer from './components/PdfViewer';

function App() {
    
    return (
	    <div className="App">
	    <header>

	</header>
	    <article className="App-header">
            	    < Dragon />  
	    <a
  	className="App-link"
	href="https://www.linkedin.com/in/annonch/"
	target="_blank"
	rel="noopener noreferrer"
            >
            Christopher Hannon | chris@nomadic-code.net
	</a>
            <p className="App-desc">
	    Software Engineer | Computer Scientist
        </p>

	    </article>
	    </div>
            
    );
}

export default App;
